<template>
  <div>
    <span>Deseja arquivar o projeto: </span>
    <strong> {{ `${item.project_code} - ${item.project_name}` }}</strong>
    <br />
    <div
      :style="{
        paddingTop: '30px',
        display: 'flex',
        flexDirection: 'row',
        gap: '10px',
        justifyContent: 'center',
      }"
    >
      <b-button variant="danger" @click="confirmArchiveProject"
        >Arquivar</b-button
      >
      <b-button variant="outline-secondary" @click="closeDeleteModal"
        >Cancelar</b-button
      >
    </div>
  </div>
</template>

<script>
import { BButton } from 'bootstrap-vue';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
export default {
  components: {
    BButton,
  },
  props: {
    item: { type: Object, default: () => {} },
  },

  data() {
    return {
      showModal: false,
    };
  },

  methods: {
    async confirmArchiveProject() {
      this.$store
        .dispatch('arquiveProject', {
          id: this.item.id,
        })
        .then((resp) => {
          if (resp) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Projeto arquivado com sucesso',
                icon: 'SuccessIcon',
                variant: 'success',
              },
            });
          }
          this.closeDeleteModal();
        })
        .catch((err) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Erro ao arquivar projeto',
              icon: 'ErrorIcon',
              variant: 'success',
            },
          });
          console.log(err);
        });
    },

    closeDeleteModal() {
      this.$emit('closeDeleteModal');
    },
  },
};
</script>
